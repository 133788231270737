<template>
    <div class="h-90 ml-2 mr-3">
        <div class="pb-10 h-100">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div>
                    <div class="breadcrumbactive">
                        <div>
                            <span class="pointer" @click="goToCompanyList">Company</span>
                            <span class="pointer" @click="goToListView">Departments</span>
                            <div >View</div>
                        </div>
                    </div>
                </div>
                <div>
                    <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="btn btn-new-secondary ml-5 float-right" @click="editDepartment" >Edit</button>
                </div>
            </div>
            <!-- <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <div class="header-slant-tab">
                        <span class="text-secondary fs-14" >View</span>
                    </div>
                    <div class="header-slant-tab-border"></div>
                    
                </div>
                
            </div> -->
            <div class="create-company-container w-100 h-100" style="border-top-left-radius: 0;">
                <div class="text-center fw-600 mb-3">
                    <span class="text-secondary fs-16">View</span>
                </div>
                <div class="row align-items-center justify-content-center" v-if="ajax_call_in_progress">
                    <div class="col-lg-12 py-5 px-3 mt-3">
                        <div class="d-flex align-items-center justify-content-center">
                            <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                        </div>
                    </div>
                </div>
                <div v-else class="create-company-header d-flex align-items-center justify-content-between">
                    <span class="fs-14 pl-4 text-white">Department Information</span>
                    <span class="pointer" @click="showHideContent('depart_info')">
                        <i class="text-white" :class="{'icon-chevron-double-down':is_show_hide_depart_info, 'icon-chevron-double-up':!is_show_hide_depart_info}" style="font-size: 20px;"></i>
                    </span>
                </div>
                <div v-if="is_show_hide_depart_info" class="px-6">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                            <div class="w-100">
                                <label class="input-label">Company Name</label>
                                <div class="d-flex align-items-center w-100">
                                        <div class="input-field">
                                            {{depart_edit_data.company_name}}
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                            <div class="w-100">
                                <label class="input-label">Department Name</label>
                                <div class="d-flex align-items-center">
                                    <div class="input-field" style="word-break: break-all !important;">
                                        {{depart_edit_data.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                            <div class="w-100">
                                <label class="input-label">Business Unit Name</label>
                                <div class="d-flex align-items-center w-100">
                                    <div class="input-field">
                                        {{depart_edit_data.business_unit_name || "-"}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 mt-5">
                            <div class="w-100">
                                <span class="input-label">Started On (DD/MM/YYYY)</span>
                                <div class="d-flex align-items-center mt-3">
                                    <div class="input-field">
                                        {{depart_edit_data.started_on || "-"}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-3 col-md-6 col-sm-12 mt-5">
                            <div class="w-100">
                                <label class="input-label">Business Unit Code *</label>
                                <div class="d-flex align-items-center">
                                    <input type="text" maxlength="100" v-validate="'required'" v-model="department_name" class="input-field" name="department_name"
                                        placeholder="1012" />
                                    <span>
                                        <el-tooltip class="item" effect="dark" content="0" placement="top">
                                                <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/settings-blue.png" class="ml-2 v-hidden" style="width:25px" alt="">
                                            </el-tooltip> -->
                                    <!-- </span>
                                </div>
                            </div>
                        </div> --> 
                        
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 mt-5">
                            <div class="w-100">
                                <label class="input-label">Description</label>
                                <textarea rows="5" type="text" v-model="depart_edit_data.description" :readonly="true" maxlength="4000" class="input-field input-textarea"
                                    name="decription" placeholder="Descritpion" />
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="create-company-container w-100 h-100" style="border-top-left-radius: 0;">
                <div class="create-company-header d-flex align-items-center justify-content-between">
                    <span class="fs-14 text-white">Contact Information</span>
                    <span class="pointer" @click="showHideContent('contact_info')">
                        <i class="text-white" :class="{'icon-chevron-double-down':is_show_hide_contact_info, 'icon-chevron-double-up':!is_show_hide_contact_info}" style="font-size: 20px;"></i>
                    </span>
                </div>
                <div v-if="is_show_hide_contact_info" class="px-3">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12 mt-5">
                            <div class="w-100">
                                <label class="input-label d-flex align-items-center justify-content-between">Department Head</label>
                                <div class="d-flex align-items-center">
                                    <div class="input-field">
                                        {{depart_edit_data.department_head_name || "-"}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12 mt-5">
                            <div class="w-100">
                                <label class="input-label d-flex align-items-center justify-content-between">Primary Email </label>
                                <div class="d-flex align-items-center">
                                    <div class="input-field">
                                        {{depart_edit_data.primary_email || "-"}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-for="(alt_email, alt_email_index) in depart_edit_data.alternate_email" :key="alt_email_index+'alt_email'" class="col-lg-4 col-md-6 col-sm-12 mt-5">
                            <div class="w-100">
                                <span class="input-label d-flex align-items-center justify-content-between">Alternative Email {{alt_email_index+1}}
                                </span>
                                <div class="d-flex align-items-center">
                                    <div class="input-field">
                                        {{alt_email || "-"}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="w-100 mt-4">
                                <span class="input-label d-flex align-items-center justify-content-between">Primary Phone Number
                                </span>
                                <div class="d-flex align-items-center">
                                    <div class="leftType ml-0">
                                        <div class="d-flex">
                                            <multiselect v-model="primary_phone_type" 
                                                class="diginew-multiselect phone-number pointer" 
                                                :options="primary_phone_type_options"
                                                :multiple="false" :searchable="true" :internal-search="true"
                                                :show-labels="false" :clear-on-select="false"
                                                defaultCountry="IN"
                                                :onlyCountries="['IN']"
                                                :disabled="true"
                                                placeholder=""
                                                style="width: 80px !important;"
                                                :close-on-select="true" :options-limit="280" :max-height="200"
                                                :show-no-results="true" :hide-selected="false">
                                                <template slot="singleLabel" slot-scope="props">
                                                    <!-- <img class="option__image" :src="props.option.name"> -->
                                                    <span style="font-size: 12px">{{ props.option.name }}</span>
                                                </template>
                                                <template slot="option" slot-scope="{ option }">
                                                    <div class="d-flex flex-row">
                                                        <!-- <img :src="option.image" style="margin-right: 10px;" /> -->
                                                        <span class="ml-2">
                                                            <span style="font-size: 12px">{{ option.name }}</span>
                                                        </span>
                                                    </div>
                                                </template>
                                            </multiselect> 
                                        </div>
                                    </div>
                                    <div class="profile-primay-phone ml-2 w-100" style="position: relative;">
                                        <div>
                                            <vue-tel-input name="primary_phone" :enabledCountryCode="false"
                                                :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                                class="teleContact w-100" ref="telInput"
                                                :disabled="true"
                                                placeholder="Enter Primary Phone Number"
                                                v-model="depart_edit_data.phone_number.phone_number">
                                            </vue-tel-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-for="(alterante_phone, alt_phone_index) in depart_edit_data.alternate_phone_numbers" :key="alt_phone_index+'alt'" class="col-lg-4 col-md-6 col-sm-12 mt-5">
                            <span class="input-label d-flex align-items-center justify-content-between">Alternate Phone Number {{alt_phone_index+1}}
                                <span class="pointer" @click="deleteAlternatePhone">
                                    <i class="icon icon-minus-circle text-danger"></i>
                                </span>
                            </span>
                            <div class="d-flex align-items-center">
                                <div class="leftType ml-0">
                                    <div class="d-flex">
                                        <multiselect v-model="alterante_phone.phone_type"
                                            class="diginew-multiselect phone-number pointer" style="width: 128px" id="ajax"
                                            :options="primary_phone_type_options"
                                            :multiple="false" :searchable="true" :internal-search="true"
                                            :show-labels="false"
                                            :disabled="true"
                                            :clear-on-select="false" :close-on-select="true" :options-limit="280"
                                            :max-height="200" :show-no-results="true" :hide-selected="false">
                                            <template slot="singleLabel" slot-scope="props">
                                                <!-- <img class="option__image" :src="props.option.name"> -->
                                                <span style="font-size: 12px">{{ props.option }}</span>
                                            </template>
                                            <!-- <template slot="singleLabel" slot-scope="props">
                                                <img class="option__image" :src="props.option.image" :alt="props.option.name">
                                            </template> -->
                                            <template slot="option" slot-scope="{ option }">
                                                <div class="d-flex flex-row">
                                                    <!-- <img
                                                        :src="option.image"
                                                        style="margin-right: 10px;" /> -->
                                                    <span class="ml-2">
                                                        <span style="font-size: 12px">{{ option }}</span>
                                                    </span>
                                                </div>
                                            </template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="profile-primay-phone ml-2 w-100" style="position: relative;">
                                    <div>
                                        <vue-tel-input
                                            :name="'alternate_phone'+alt_phone_index"
                                            :enabledCountryCode="false"
                                            :disabledFormatting="false" :validCharactersOnly="true" :maxLen="15"
                                            class="w-100" ref="telInput"
                                            :placeholder="'Enter Alternate Phone Number'+alt_phone_index"
                                            data-vv-as="Alternate Phone"
                                            :disabled="true"
                                            v-model="alterante_phone.phone_number">
                                        </vue-tel-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {HalfCircleSpinner} from 'epic-spinners';
    import companies from "../mixins/companies";
    export default ({
        mixins: [companies],
        components: {
            HalfCircleSpinner
        },
        data() {
            return {
                ajax_call_in_progress: false,
                phone_type:'',
                depart_edit_data: {},
                is_show_hide_depart_info: true,
                is_show_hide_contact_info: true,
                primary_phone_type: {},
                primary_phone_type_options: [{
                        name: 'Home',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/home-contact-icon.svg'
                    },
                    {
                        name: 'Mobile',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/mobile-contact-icon.svg'
                    },
                    {
                        name: 'Whatsapp',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/whatsapp-contact-icon.svg'
                    },
                    {
                        name: 'Line',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/line-contact-icon.svg'
                    },
                    {
                        name: 'Work',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/work-contact-icon.svg'
                    },
                    {
                        name: 'Telegram',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/telegram-contact-icon.svg'
                    },
                    {
                        name: 'Others',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/others-contact-icon.svg'
                    },
                    {
                        name: 'Messenger',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/messenger-contact-icon.svg'
                    },
                    {
                        name: 'Fax',
                        image: 'https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/fax-contact-icon.svg'
                    }
                ],
            }
        },
        methods: {
            editDepartment(){
                this.$router.push(`/departmentunit/edit/cmpid/${this.$route.params.company_id}/${this.$route.params.id}`);
            },
            goToCompanyList() {
                this.$router.push('/company/view/cmpid/' + this.$route.params.company_id);
            },
            goToListView() {
                this.$router.push('/departmentunit/listview/cmpid/' + this.$route.params.company_id);
            },
            showHideContent(type){
                if(type == 'depart_info'){
                    this.is_show_hide_depart_info = !this.is_show_hide_depart_info;
                } else if(type == 'contact_info'){
                    this.is_show_hide_contact_info = !this.is_show_hide_contact_info;
                }
            },
            async getdepartmentUnitData(id) {
                this.ajax_call_in_progress = true;
                try {
                    let response = await this.getDepartmentUnitById(id);
                    if(response.status_id == 1) {
                        this.depart_edit_data = response.response;
                        console.log("hi")
                        // this.phone_type=this.depart_edit_data.alternate_phone_numbers
                        // if(this.depart_edit_data.alternate_phone_numbers){
                        //     this.depart_edit_data.alternate_phone_numbers.forEach(x => {
                        //         x.phone_type ={
                        //             name:  x.phone_type,
                        //             image: ''
                        //         }
                                
                        //     });
                        // }
                        console.log("hi1")
                        console.log(this.depart_edit_data.alternate_phone_numbers)
                        this.primary_phone_type = {
                            name: this.depart_edit_data.phone_number.phone_type,
                            image: ''
                        }
                    }
                    this.ajax_call_in_progress = false;
                }
                catch(err) {
                    this.ajax_call_in_progress = false;
                }
            }
        },
        mounted() {
            this.getdepartmentUnitData(this.$route.params.id);
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
        },
        created() {
        }
    })
</script>

<style scoped>
    .create-company-container {
        background-color: #fff;
        padding: 15.5px 0 50px 0;
        border-radius: 6px;
    }
    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
    }
    .input-label {
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 0.39px;
        text-align: left;
        color: #0475f3;
        padding: 0 0 6px 10px;
    }
    .input-field {
        border-radius: 8px !important;
        border: solid 0.5px #3f3f3f;
        background-color: #fff;
        padding-top: 8px !important;
        width: 100%;
        min-height: 38px !important;
    }
    .btn-new-cancel {
        color: #fff;
        background-color: #d51650;
        border-radius: 5px;
        padding-left: 35px;
        padding-right: 35px;
        text-transform: none;
        font-weight: 500;
        border: 2px solid #d51650;
        line-height: 30px;
        box-shadow: none;
    }
    .btn-new-secondary {
        color: #fff;
        border-radius: 5px;
        padding-left: 35px;
        padding-right: 35px;
        text-transform: none;
        font-weight: 500;
        line-height: 30px;
        box-shadow: none;
        float:right!important;
    }
</style>